.HomepageBanner{background:none; padding:0;
    h1{font-size:80px; color:var(--primary-color);
    span{font-weight:400;}
    }
.container{max-width: 1280px; align-items: center;}
.bannerImage{display: flex;
    img{max-width:500px; margin-left: auto;
   }
}
img {width: 100%; height:auto;}
}

.essentialsList{
     h2{padding-bottom: 40px;}
     @media screen and (max-width:767px) {
        h2{padding-bottom:20px;}
     }
     a{display: block;}
    h3{color:var(--primary-color); padding-top:20px; white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block; font-size:var(--font-extralarge);}
        .lightGreyBG{padding:35px 20px; border-radius: var(--border-r-8); box-shadow: 0 0 12px 0px rgb(0 0 0 / 0%); -webkit-transition:all .4s ease-in-out; -moz-transition:all .4s ease-in-out; -o-transition:all .4s ease-in-out; -ms-transition:all .4s ease-in-out;
        }
        a:hover .lightGreyBG{background: var(--white-bg); box-shadow: 0 0 12px 0px rgb(0 0 0 / 28%);}
    
}
.TopRatedShops{background:url(../../../images/top-rated-shops-bg.png) no-repeat center;
.mainHeading{color: var(--black-color);}
.white-bg{box-shadow: 0 0 14px 0px rgb(0 0 0 / 45%);}
h3{color: var(--drak-light-grey-color); font-size: var(--font-medium);}
img{width: 100%; height: auto; border-radius: var(--border-r-8);}

.rateingInfo svg{fill: #F5BF00; margin: 0 2px; cursor: pointer;}
}

body .downloadApp {
    h3{font-size:var(--font-mainHeading);} 
}

@media screen and (min-width:992px) {

body .homeCAsection { padding-bottom: 0;
 .createAccountImg {
        margin-bottom: -75px;
    }
.createAccountInfo{
    margin-left: -100px;
}
}
}