body {
    .menucatlayout {

        background: #f5f7fb;
        border-radius: 8px;
        padding: 15px;

        .select-cat {
            h4 {
                color: var(--border-color);
                font-weight: 400;
                font-size: var(--font-small);
            }

            button.btn {
                height: 40px;
                min-width: 110px
            }
        }


        .menuRow {
            margin-left: -15px;
            margin-right: -15px;
            margin-top: -15px;
            width: auto;
            padding-top: 0;

            .menuCol {
                padding: 15px;
            }
        }

        .CategoriesCheckbox {
            margin: 0;
            min-height: 150px;
            height: 100%;
            width: 100%;
            flex-wrap: wrap;
            position: relative;

            .MuiCheckbox-root {
                width: 100%;
                height: 100%;
                background: var(--white-color);
                border-radius: var(--border-r-4);
                align-items: baseline;

                &.Mui-checked {
                    background: var(--primary-color);

                    svg path {
                        fill: var(--white-color);
                    }
                }

                &.Mui-checked~.MuiFormControlLabel-label {
                    color: var(--white-color);
                }

                svg {
                    margin-left: auto;
                    padding: 1px;

                    path {
                        fill: var(--primary-color);
                    }
                }
            }

            .MuiFormControlLabel-label {
                width: 100%;
                position: absolute;
                bottom: 0;
                padding: 15px;
                color: var(--primary-color);
                font-size: var(--font-small);
                line-height: 24px;
                text-transform: initial;
                font-weight: 500;
            }
        }

    }





    .menucatProductlayout {


        @media screen and (max-width:767px) {

            .menuProductRow {
                padding-top: 5px;
            }

        }

        .menuCatlist-bg {
            padding: 15px;
            background: var(--light-blue-bg);
            border-radius: var(--border-r-4);

            li {

                display: block;
                padding: 0;
                border-radius: var(--border-r-4);

                a {
                    padding: 15px;
                    font-size: var(--font-small);
                    color: var(--black-color);
                    font-weight: 400;
                    display: flex;
                    align-items: center;

                    cursor: pointer;
                    margin: 0;

                    svg {
                        margin-left: auto;
                        font-size: 18px;
                        fill: var(--drak-light-grey-color);
                    }
                }

                &:hover,
                &.active {
                    background: var(--white-color);

                    a {
                        color: var(--primary-color);
                    }

                    svg {
                        fill: var(--primary-color);
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                &.listHeading {
                    background: var(--gradients-color);
                    border-radius: var(--border-r-4);

                    a {
                        color: var(--white-color);

                        svg {
                            font-size: 30px;
                            fill: var(--white-color);
                        }
                    }
                }
            }
        }

        .CatlistInfo {
            background: var(--light-blue-bg);
            border-radius: var(--border-r-4);
            padding: 20px;

            @media screen and (max-width:1199px) {
                margin-top: 30px;

            }

            @media screen and (min-width:1200px) {

                margin-left: 30px;

            }

            .catlistHeader {
                background: var(--tablethcolor);
                padding: 25px 15px;
                font-family: var(--primary-font);
                font-weight: 700;
                color: var(--primary-color);
                margin-top: 25px;
                margin-bottom: -6px;
                border-radius: 6px 6px 0 0;
            }
        }

        .CatlistRow {
            padding: 10px 15px;

            align-items: center;
            background: var(--white-color);
            border-radius: 6px;
            box-shadow: 0px 4px 4px 0px #92929226;
            margin-bottom: 15px;

            @media screen and (max-width:767px) {
                margin-bottom: 0px;
                margin-top: 15px;


            }

            .catCol {


                .productimgTxt {
                    display: flex;
                    align-items: center;

                    .productimg {
                        width: 100%;
                        max-width: 72px;
                        margin-right: 15px;

                        img {
                            width: 72px;
                            height: 72px;
                            display: block;
                        }
                    }

                    .productinfo {
                        h3 {
                            font-size: var(--font-normal);
                            font-weight: 700;
                            color: var(--drak-light-grey-color);
                            padding-bottom: 5px;
                        }
                    }
                }

                span.popupTxt {
                    font-size: var(--font-small);
                    font-weight: 400;
                    color: var(--drak-light-grey-color);
                    padding: 0;
                    background: none;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                .priceBtn {
                    border: 1px solid #D9D9D9;
                    display: block;
                    padding: 0;
                    border-radius: 4px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                }

                .addPricetxt {
                    justify-content: center;
                    font-weight: 500;
                    color: var(--drak-light-grey-color);
                }

                .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
                    color: var(--drak-grey-color);
                }

                .css-1yjjitx-MuiSwitch-track {
                    background: #D9D9D9;
                }

                .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
                    color: var(--primary-color);
                }

                .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
                    background: #B3D9F3;
                }

                @media screen and (max-width:767px) {
                    span.iPhoneTxt {
                        font-weight: 500;
                        color: var(--primary-color);
                        padding: 15px 0 5px 0;
                        display: block;
                    }

                    .checkBtn {
                        margin-bottom: -12px;
                        display: block;
                        margin-top: -8px;
                    }
                }
            }
        }

    }





    .modalPopupTxt {
        .modalPopupTxt-inner {

            .modalHeading {
                border-bottom: 1px solid var(--border-color);
                margin: 0 -30px 0;
                @media screen and (max-width:767px) {
                    margin: 0 -20px 0; 
                }
                padding-bottom: 25px;

                .closepopup {
                    margin: 27px 20px;
                    @media screen and (max-width:767px) {
                        margin:21px 20px; 
                    }
                }
            }

            .productimgTxt {
                display: flex;
                align-items: center;
                padding: 20px 0;

                .productimg {
                    width: 100%;
                    max-width: 90px;
                    margin-right: 15px;

                    img {
                        width: 90px;
                        height: 90px;
                        display: block;
                    }
                }


                .productinfo {
                    h3 {
                        font-size: var(--font-normal);
                        font-weight: 700;
                        color: var(--primary-color);
                        padding-bottom: 5px;
                    }

                }
            }

            span.popupTxt {
                font-size: var(--font-small);
                font-weight: 400;
                color: var(--primary-color);
                padding: 0;
                background: none;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            label {
                font-size: var(--font-normal);
                font-family: var(--primary-font);
                font-weight: 500;
                color: var(--drak-light-grey-color);
                padding-bottom: 9px;
            }

            .form-control {
                color: var(--primary-color);

                svg {
                    fill: var(--primary-color);
                }


            }
        }


        &.modalAddProduct .modalPopupTxt-inner {
            max-width: 820px;

            .addProductImg label {
                flex-wrap: wrap;
                justify-content: center;
                display: flex;
                text-align: center;
                text-transform: none;
                background: #DFE3E8;
                padding: 30px;

                img {
                    max-width: 50px;
                }
            }

        }


        &.modalAddQuantity .modalPopupTxt-inner {

            .productimgTxt {

                text-align: center;
                justify-content: center;
                flex-wrap: wrap;

                .productimg {
                    margin-right: 0;
                }

                .productinfo h3 {
                    font-size: var(--font-subheading);
                    color: var(--drak-light-grey-color);
                }
            }

            span.popupTxt {
                justify-content: center;
                font-size: var(--font-normal);
                font-weight: 400;
                color: var(--drak-light-grey-color);
                padding-top: 5px;

                b {
                    padding-right: 5px;
                }
            }
        }
    }

}