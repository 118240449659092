.storeBG {
  background: url(../../../images/storeBG.png) no-repeat center;
  background-size: cover;

  .storeImg {
    min-width: 85px;
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: var(--border-r-8);

    img {
      width: 100%;
      height: auto;
    }


  }

  nav li {
    color: var(--drak-grey-color);

    a {
      color: var(--drak-grey-color);
    }
  }

}

.storeListSearch {
  border-bottom: 2px solid var(--light-grey-color);
  margin-bottom: 40px;
  button:hover{background:none;}
  .searchTxt{color: var(--drak-light-grey-color); padding: 15px 0;
  input{font-size: var(--font-normal); font-family: var(--primary-font);}
  }
}

.StoreCatListleft {
  ul {
    background: var(--light-blue-bg);
    border-radius: var(--border-r-8);
    
    padding: 10px;
    @media screen and (min-width:768px) {
      margin-right: 20px;
    }

    li {
      display: block;
      padding: 0;

      .catHeading {
        display: block;
        border: none;
        padding: 15px;
        width: 100%;
        border-radius: var(--border-r-4);
        font-weight: 600;
        font-size: var(--font-normal);
        text-align: left; margin-bottom: 5px;
      }
   

    .list-btn {
      display: block;
      border: none;
      background: none;
      padding: 15px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      border-radius: var(--border-r-4);
      font-weight: 500;

      &:hover {
        background: var(--white-color);
        color: var(--primary-color);
      }
    }
  }
  }
}


.StoreCatListright{
  @media screen and (min-width:768px) {
    padding-left: 20px;
  }
.StoreCatCol{
h4{font-size: var(--font-normal); color:var(--drak-light-grey-color); font-weight:600; margin: 15px 0;}
.count-txt{color:var(--drak-light-grey-color);}
button.btn{width: 100%; background: var(--light-grey-color); border: 1px solid #D9D9D9; border-radius: 50px;
svg{margin: -2px 2px 0 0; font-size: 20px;}

}

}


}


.RadioChooseGroup {
  label {
    line-height: normal;
    margin: 0;
  }

  span {
    padding: 0;

    svg {
      fill: var(--primary-color);
    }
  }

  .labeltxt {
    padding-left: 10px;

    b {
      padding-left: 25px;
    }
  }
}

.errorMessage {
  color: red;
}

body .modalPopupTxt .modalPopupTxt-inner .ModalHeader{
  position: relative;
  margin:-30px -30px 20px;
  padding: 25px 30px;
  border-bottom: 1px solid var(--primary-bg-color);
  .closepopup{top: 15px;
    right: 10px;}
}

body .modalPopupTxt .modalPopupTxt-inner form label{
  color: var(--drak-light-grey-color);
}
.FooterModal {
  border-top: 1px solid var(--primary-bg-color);
  margin: 50px -30px 0;
  padding: 20px 30px 0;

  .itemTxt {
    font-size: var(--font-normal);
    font-weight:400;
    color: var(--drak-light-grey-color);
    .itemPrice{font-weight: 700; padding-left:3px;}
  }
}