body {



    .helplayout {
        background: var(--primary-bg-color);
        margin: 0 -20px;
        padding: 24px;

        .white-bg {
            border-radius: var(--border-r-8);
            padding: 24px;

            .borderLine.MuiBox-root.css-0 {
                border-bottom: 1px solid #C4CDD5;
                margin: 30px -24px;
            }
        }

        .phonemailInfo {
            display: flex;
            flex-wrap: wrap;

            .info {
                border: 1px solid #D9D9D9;
                padding: 6px 20px 7px;
                display: flex;
                align-items: center;
                margin: 0 15px 0 0;
                border-radius: 20px;
                font-size: 16px;
                color: #666;

                svg {
                    margin-right: 8px;
                }

                @media screen and (max-width:767px) {
                    margin: 0 0 10px 0;
                }

            }

        }


        .supportInfo {
            flex-wrap: wrap;

            span {
                position: relative;
                padding: 0 40px 0 50px;

                svg {
                    position: absolute;
                    left: 0;
                    top: 7px;
                    font-size: 33px;
                }

                @media screen and (max-width:767px) {
                    margin: 0 0 10px 0;
                }
            }
        }

        @media screen and (max-width:767px) {
            .typeInfo h3.font-36 {
                font-size: 24px;
                ;
            }
        }
    }
}