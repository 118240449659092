header.header-bg {
  background: var(--white-color);
  box-shadow: none;
  .MuiToolbar-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .main-logo {
    .logo-txt {
      color: var(--primary-color);
      font-family: var(--primary-font);
      width: auto;
      height: 60px; 
     
      display: flex;
    }
  }
  .location-info {
    color: var(--primary-color);
    padding-left: 15px;
    font-size: var(--primary-font-size);
    font-family: var(--primary-font);
    font-weight: 400;
    span {
      padding: 0 5px;
    }
  }

  .header-rightsidebar {
    .search-bg {
      background: none; width: auto;
    }
    .svg-icon {
      fill: var(--drak-grey-color);
      &.SearchIcon{font-size: 28px;}
    }
   
    .search-input {
      color: var(--drak-grey-color);
    }
    .btn{white-space: nowrap; padding: 0 30px;}
  }
  .cartIcon {
    cursor: pointer;
    margin: 4px 10px 0 5px;
    &.cartIconMobile{margin:0 10px;}
    .svg-icon {
      fill: var(--drak-grey-color);  
  }
    .carbadge .MuiBadge-badge {
      background: var(--drak-grey-color);
    }
  }
  .ProfileMenuIcon {
    .userName {
      width: 40px;
      height: 40px;
      background: var(--primary-color);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-small);
      cursor: pointer;
    }
    svg {
      fill: var(--primary-color);
    }
  }
}
body .addTocartBar {

  @media screen and (max-width:767px) {
    &.css-ve9ogo{
width: 300px;
  }
}
  .CartHeader {
    background: var(--primary-bg-color);
    padding: 10px 20px;
    .cartItems {
      font-size: var(--font-small);
      color: var(--primary-color);
      border-right: 1px solid var(--border-color);
      padding: 10px 15px 10px 0;
      margin-right: 5px;
    }
    button.closeBtn {
      min-width: 40px;
      background: none; padding-right: 0;
      &:hover {
        background: none;
      }
      svg {
        fill: var(--primary-color);
      }
    }
  }

  .addCartProductList {
    height: calc(100vh - 136px);
    overflow-y: auto;
    li {
      border-bottom: 1px solid var(--primary-bg-color);
      padding: 12px 20px;
      &:last-child {
        border-bottom: none;
      }
      @media only screen and (max-width:767px){
        flex-wrap: wrap;
      }
    }

    .qutPrice {
      min-width: 180px;
padding-top: 15px;
      @media only screen and (min-width:768px){
        padding-top:0;
        justify-content: flex-end;      
        padding-left: 20px;
        margin-left: auto;
      }
      

      .qutBox {
        display: flex;
        align-items: center;
        border: 1px solid var(--primary-color);
        border-radius: 100px;
        position: relative;
        overflow: hidden;
        fieldset {
          display: none;
        }
        input[type="number"] {
          border: none;
          max-width: 50px;
          appearance: none;
          padding: 0;
          text-align: center;
          height: 36px;
          min-width: 60px;
          color: var(--primary-color);
        }
        .MuiFormControl-root {
          @media only screen and (min-width:767px){
          margin-right: -15px;
          }
        }
        button {
          background: var(--white-color);
          border: none;
          width: 28px;
          height: 37px;
          position: relative;
          z-index: 1;
          color: var(--primary-color);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin: 0;
            border: none;
            font-size: 19px;
          }
        }
      }
    }
  }
  .CartFooter {
    padding: 15px;
    border-top: 1px solid var(--primary-bg-color);

    button.btn{padding: 0 40px;}
  }
}
