footer.footer-bg{
background: var(--gradients-color); padding:50px 15px;
h2{color: var(--white-color); font-size: 24px;}
.footer-txt p{color: var(--white-color);}
.footelinks li{ padding:10px 0; 
a{color: var(--white-color);}
}

.footerShopBtn{display: inline-block; height:48px; line-height:48px; padding: 0 30px; border-radius: 50px; font-size: var(--font-normal); font-family: var(--primary-font); font-weight:500; color: var(--drak-light-grey-color);
    @media screen and (min-width:767px) {
        margin-left: auto; 
     }
&:hover{color: var(--primary-color);}
}

}