header.header-bg.white-bg {
  background: var(--primary-bg-color);
  .header-rightsidebar {
    .search-bg {
      background: none;
    }
  }
}

.login-wrapper {
  padding: 50px 0;
  .login-bg {
    background: var(--primary-bg-color);
    border-radius: var(--border-r-4);
    padding: 50px;
    @media screen and (max-width:767px) {
      padding:30px;
    }
    @media screen and (min-width:767px) and (max-width:1023px) {
      padding:30px 40px;
    }
    h2 {
      padding: 0 0 15px 0;
      margin: 0;
    }
  }
  .btn-row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .social-login-btn {
    border: 1px solid var(--black-color);
    color: var(--black-color);
    width: 100%;
    margin: 0 auto;
    max-width: 200px;
  }
  .social-login-btn:hover {
    background: var(--white-color);
  }
}
