
.Thanksbg{ 
  height:calc(100vh - 80px);
    display: flex;
    align-items: center; position: relative; overflow: hidden;

 &::before{content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height:200%;
  background: linear-gradient(to right, white 70.5%, #0070BC 30%);
  width: 100%;
  z-index: -1;}
  .thankTxt{max-width:280px;}

.goHomeBtn {
  a.btn {
    height: 40px;
    display: inline-block;
    line-height: 40px;
    padding: 0;
    border-radius: 50px;
    margin-top: 30px;
    width: 100%;
    max-width: 140px;
  }
}

.container{ }

.ThanksRightCol img{border-radius: 48% 48% 14px 14px;
  max-width: 300px;}

}