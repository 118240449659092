.shopprofilelayout {
   padding:0px;
   border-radius: var(--border-r-8);
   @media screen and (max-width:767px) {
       

      .w-80{width:100%}

   }

   .MuiGrid-item {
      padding: 35px 15px 0px;
      color: var(--primary-color);
      font-size: var(--font-small);
      @media screen and (max-width:767px) {
         padding:0px 15px 25px;
      }

      label {
         display: block;
         font-weight: 500;
         padding-bottom: 5px;  
         color: var(--drak-light-grey-color);
      }
   }
   .shopSigniconArea{
      margin-bottom:100px;
      @media screen and (max-width:767px) {
         margin-bottom:50px;
      }
   .shopIcon{
      width:168px;
      height: 168px;
      @media screen and (max-width:767px) {
         width:138px;
         height: 138px;
         padding:30px;
         margin-bottom:15px;
         img{width: 100%;}
      }
      margin: auto;
      border: 2px dotted var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-bottom: 30px;}
   }
   .shopSignBtn .btn{padding: 0 30px;}
}