body .myaccountInfo {

    .profileBtnRow {
        padding: 25px;
        border-bottom: 1px solid #C4CDD5;
    }

    .myAccount-leftRight {
        padding: 25px;
        min-height: 500px;

        @media screen and (max-width:767px) {
            padding: 15px;
            min-height: auto;
        }

        .myaccount-left {
            background: var(--light-blue-bg);
            width: 100%;
            height: 100%;

            .subMenuArrow {
                font-size: var(--font-normal);
                font-weight: 500;
                color: var(--primary-color);
            }

            ul li {
                width: 100%;
                padding: 1px 0 1px 15px;

                a {
                    font-size: var(--font-15);
                    font-weight: 500;
                    width: 100%;
                    background: none;
                    padding: 18px 15px;
                    display: block;
                    border-radius: 4px 0 0 4px;
                    color: var(--black-color);

                    &:hover,
                    &.active {
                        background: var(--white-color);
                        color: var(--primary-color);
                    }
                }
            }

        }

        .myaccountRight {
            padding-left: 25px;

            @media screen and (max-width:767px) {
                padding-left: 0px;
            }


            .supporcontainer .supportInfo {
                flex-wrap: wrap;

                span {
                    position: relative;
                    padding: 0 40px 0 50px;

                    svg {
                        position: absolute;
                        left: 0;
                        top: 7px;
                        font-size: 33px;
                    }

                    @media screen and (max-width:820px) {
                        margin: 0 0 10px 0;
                    }
                }
            }

        }

        .customerOrderTablelayout .customerOrderTable {
            table thead th {
                background: var(--primary-color);
                border-bottom: none;
                color: var(--white-color);
                white-space: nowrap;

                svg {
                    fill: var(--white-color);
                    margin: 0 0 -7px 6px;
                }

                &:first-child {
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }
            }

            table tr td {
                color: #676879;
                font-weight: 400;
                font-size: var(--font-small);
            }

        }

    }

    .orderphonemailInfo {
        .info {
            border: 1px solid #D9D9D9;
            padding: 6px 20px 7px;
            display: flex;
            align-items: center;
            margin: 0 15px 0 0;
            border-radius: 20px;
            font-size: 16px;
            color: var(--drak-light-grey-color);

            svg {
                margin-right: 8px;
            }

            @media screen and (max-width:767px) {
                margin: 0 0 10px 0;
            }

            a {
                color: var(--drak-light-grey-color);
            }
        }
    }
}

.accordionOuter {
    max-width: 800px;
    margin: auto; padding-bottom: 20px;

    .accordionHeading {

        svg.MuiSvgIcon-root {
            fill: #CDCDCD;
            font-size:32px;
        }

        .Mui-expanded svg.MuiSvgIcon-root {
            fill: var(--primary-color);

        }

        .MuiAccordionSummary-content {
            margin: 22px 0;
            position: relative;
            padding-left: 45px;

            &::before {
                content: "";
                display: block;
                width: 24px;
                height: 24px;
                margin-right: 20px;
                position: absolute;
                left: 0;
                top: 0;
                background: #CDCDCD;
                border-radius: 30px;
            }

            &.Mui-expanded:before {
                background: var(--primary-color);
            }

            h2 {
                font-size: var(--font-normal);
                font-weight: 400;
                color: #353C41;
                line-height: 26px;
            }
        }

        &.Mui-expanded {
            background: #FAFBFF;
        }

    }

    .MuiPaper-root.Mui-expanded {
        margin: 0;
    }

    .accordionInnerOuter {
        border-bottom: 1px solid #ddd;

        &::before {
            display: none;
        }

        .accordionInfo {
            padding: 20px;

            h2 {
                font-size: var(--font-medium);
                font-weight: 600;
                line-height: 26px;
                color: var(--primary-color);
                padding-bottom: 15px;
            }
        }
    }

    .accordionInnerOuter:last-child {
        border-bottom: none;
    }

}