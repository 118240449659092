.orderTablelayout {
   margin: 0px 0 0 0;

   @media screen and (max-width:767px) {
      margin: 15px 0 0 0;
   }

   .orderTable {
      table thead th {
         background: var(--tablethcolor);
         border-bottom: none;
         color: var(--drak-grey-color);
         white-space: nowrap;

         svg {
            fill: var(--primary-color);
            margin: 0 0 -7px 6px;
         }

         &:first-child {
            border-radius: 4px 0 0 4px;
         }

         &:last-child {
            border-radius: 0 4px 4px 0;
         }
      }

      table tbody tr {
         background: var(--white-color);

         td {
            background: none;
            border-bottom: none;
            margin-bottom: 15px;
            color: var(--drak-grey-color);
            padding: 20px 15px;
            font-size: var(--font-small);
            font-family: var(--primary-font-size);

            &:first-child {
               border-radius: 4px 0 0 4px;
            }

            &:last-child {
               border-radius: 0 4px 4px 0;
            }
         }

         &:first-child td {
            border-top: none;
         }

         &:hover {
            background-color: var(--white-color);
         }
      }

      table tbody tr:nth-child(even) {
         background-color: #F5F7FB;
      }
   }

   .tablebottombar {
      .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
         background: var(--white-color);
         border: 1px solid var(--primary-color);
         border-radius: 4px;
         line-height: 22px;
         padding-bottom: 0;

         .MuiSelect-select {
            padding-bottom: 2px;
         }
      }

      .Mui-disabled svg {
         opacity: 0.5;
      }

      button svg {
         fill: var(--primary-color);
      }

      .css-1psng7p-MuiTablePagination-spacer {
         display: none;
      }
   }
}