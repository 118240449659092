* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
}

:root {
  --white-color: #fff;
  --black-color: #000;
  --primary-bg-color: #e8f1f6;
  --light-blue-bg: #F5F7FB;
  --drak-grey-color: #454545;
  --drak-light-grey-color: #666;
  --light-grey-color: #F6F6F6;
  --primary-color: #0070BC;
  --red-color: #f00;
  --tablethcolor:#C3E7FF;
  --gradients-color: linear-gradient(180deg, #0070BC, #0096FC);
  --gradients-color-hover: linear-gradient(180deg, #0096FC, #0070BC);
  --primary-font: "Roboto",'Arial', sans-serif;
  --primary-font-size: 15px;
  --font-xxs: 13px;
  --font-small: 14px;
  --font-normal: 16px;
  --font-medium: 18px;
  --font-large: 20px;
  --font-extralarge: 22px;
  --font-subheading: 24px;
  --font-mainHeading: 40px;
  --border-r-4: 4px;
  --border-r-8: 8px;
  --border-color: #94A3B0;

  @media screen and (max-width:767px) {
    --font-xxs: 13px;
    --font-small: 14px;
    --font-normal: 15px;
    --font-medium: 16px;
    --font-large: 18px;
    --font-extralarge: 20px;
    --font-subheading: 21px;
    --font-mainHeading: 28px;
  }
}

body {
  margin: 0;
  padding-top: 80px;
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
  font-weight: 400;

.text-align-center{text-align: center;}
.text-align-right{text-align: right;}
.mw-150{min-width:150px;}
  .gradients-bg {
    background: var(--gradients-color);
    color: var(--white-color);
  }

  button.btn.gradients-bg:hover {
    background: var(--gradients-color-hover);
    box-shadow: none;
  }

  .blue-bg {
    background: var(--primary-bg-color);
  }

  .light-blue-bg {
    background: var(--light-blue-bg);
  }

  .white-bg {
    background: var(--white-color);
  }

  .lightG-bg {
    background: var(--light-grey-color);
  }

  .container {
    max-width: 1280px;
    width: 100%;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-fluid {max-width: 100%;}

  @media screen and (max-width:1025px) {
  .maxWidthLg{max-width: 100%;}

  }
  a {
    text-decoration: none;
    color: var(--primary-color);
  }

  p {
    font-family: var(--primary-font);
    font-size: var(--primary-font-size);
    color: var(--drak-grey-color);
    line-height: 22px;
  }

  .cursorP {
    cursor: pointer;
  }

  .boxShadowNone {
    box-shadow: none;
  }

  .mainHeading {
    font-family: var(--primary-font);
    font-size: var(--font-mainHeading);
    font-weight: 700;
    color: var(--primary-color);
  }

  .pColor {
    color: var(--primary-color) !important;
  }

  .wColor {
    color: var(--white-color);
  }

  .gColor {
    color: var(--drak-light-grey-color);
  }

  .bColor {
    color: var(--black-color);
  }

  .fw-400 {
    font-weight:400;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-700 {
    font-weight: 700;
  }

  .font-xxs {
    font-size: var(--font-xxs);
    font-family: var(--primary-font);
    line-height: 20px;
  }

  .font-small {
    font-size: var(--font-small);
    font-family: var(--primary-font);
    line-height: 22px;
  }

  .font-normal {
    font-size: var(--font-normal);
    font-family: var(--primary-font);
    line-height: 26px;
  }

  .font-medium,
  .font-large,
  .font-extralarge,
  .font-subheading {
    font-family: var(--primary-font);
    

    a {
      color: var(--drak-grey-color);
    }
  }

  .font-medium {
    font-size: var(--font-medium);
  }

  .font-large {
    font-size: var(--font-large);
  }

  .font-extralarge {
    font-size: var(--font-extralarge);
  }

  .font-subheading {
    font-size: var(--font-subheading);
  }

  .font-32{font-size: 32px;}
  .font-36{font-size: 36px;}
  .font-70{font-size: 70px;}

  .m-h-auto {
    min-height: auto;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .ml-auto {
    margin-left: auto;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  .mr-auto {
    margin-right: auto;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-15 {
    margin-right: 15px;
  }

  .my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mx-10 {
    margin: 0 10px;
  }

  .mx-15 {
    margin: 0 15px;
  }

  .p-0 {
    padding: 0 !important;
  }

  .p-10 {
    padding-left: 10px;
  }

  .p-20 {
    padding: 20px;
  }

  .p-25 {
    padding: 25px;
  }
  .p-30{
    padding:30px;
  }

  .px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pt-0 {
    padding-top:0px!important;
  }

  .pt-5 {
    padding-top: 5px;
  }

  .pt-10 {
    padding-top: 10px;
  }

  .pt-15 {
    padding-top: 15px;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pt-30 {
    padding-top: 30px;
  }

  .pt-40 {
    padding-top: 40px;
  }

  .pt-50 {
    padding-top: 50px;
  }
  .pb-5 {
    padding-bottom:5px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }

  .pb-20 {
    padding-bottom: 20px;
  }

  .pb-25 {
    padding-bottom: 25px;
  }

  .pb-30 {
    padding-bottom: 30px;
  }

  .pb-40 {
    padding-bottom: 40px;
  }

  .pb-50 {
    padding-bottom: 50px;
  }

  .pl-5 {
    padding-left:5px;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .pl-15 {
    padding-left: 15px;
  }

  .pl-20 {
    padding-left: 20px;
  }

  .pl-30 {
    padding-left:30px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .pr-15 {
    padding-right: 15px;
  }

  .pr-20 {
    padding-right: 20px;
  }

  .pr-25 {
    padding-right: 25px;
  }

  .py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width:767px) {
    .mt-xs-0{margin-top: 0;}
    .py-50 {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .py-60 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  @media screen and (min-width:768px) and (max-width:1023px) {
    .py-50 {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .py-60 {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }
  .d-flex {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width:767px) {

    .d-xs-none{display:none;}
    .flex-xs-wrap{flex-wrap: wrap;}

    }

  @media screen and (min-width:768px) {

  .d-sm-none{display:none;}
  
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .align-items-cenrer {
    align-items: center;
  }

  .justify-content-cenrer {
    justify-content: center;
  }

  .align-items-flex-start {
    align-items: flex-start;
  }

  .d-block {
    display: block;
  }


  .border-radius {
    border-radius: var(--border-r-4);
  }

  .border-radius-8 {
    border-radius: var(--border-r-8);
  }

  .border-1 {
    border: 1px solid var(--primary-color);
    border-radius: var(--border-r-4);
  }

  .LightBlueBG {
    background: var(--primary-bg-color);
  }

  .lightGreyBG {
    background: var(--light-grey-color);
  }



  button.btn {
    font-family: var(--primary-font);
    text-transform: initial;
    box-shadow: none;
    font-size: var(--font-small);
    font-weight: 700;
    padding: 0 20px;
    height:40px;
    line-height:40px;
    border-radius: var(--border-r-4);
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: none;
  }


  button.btn.border-btn {
    border: 1px solid var(--primary-color);
  }


  button.btn.button-radius {
    border-radius: 7px;
    height: 40px;
    line-height: 30px;
  }


  button.btn.outline-btn {
    background: var(--white-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    &:hover{background: var(--primary-color); color: var(--white-color);}
  }

  button.btn-h-40{height: 40px; line-height:40px;}

  button.border-radius-50{border-radius: 50px;}
  
  .shopBtn {
    background: linear-gradient(180deg, #71E9FF, #00CCFF);
    font-size: var(--font-large);
    font-weight: 700;
    color: var(--primary-color);
    padding: 14px 60px;
    border-radius: 50px;

    &:hover {
      background: linear-gradient(180deg, #00CCFF, #71E9FF);
    }

  }

  .border-none {
    border: none !important;
  }

  .white-btn {
    background: var(--white-color);
    color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .white-btn:hover {
    background: var(--white-color);
  }

  .primary-btn {
    background: var(--primary-color);
    color: var(--white-color);
  }

  .primary-btn:hover {
    background: var(--primary-color);
  }

  .w-20 {
    width: 20%;
  }

  .w-40 {
    width: 40%;
  }

  .w-50 {
    width: 50%;
  }

  .w-60 {
    width: 60%;
  }

  .w-80 {
    width: 80%;
  }

  .w-100 {
    width: 100%;
  }

  .formLabel {
    font-size: 15px;
    font-weight: 500;
    color: var(--drak-light-grey-color);
    padding-bottom: 5px;
    display: block;
  }

  .error {
    color: var(--red-color);
    font-size: 15px;
    font-weight: 400;
    padding-top: 5px;
  }

  .form-group {
    padding: 0 0 15px 0;

    label {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: var(--drak-light-grey-color);
    }

    .form-control {
      height: 55px;
      background: var(--white-color);
      border: 1px solid var(--drak-light-grey-color);
      border-radius: var(--border-r-4);
      outline: none;
      padding: 0 15px;
      font-family: var(--primary-font);
      font-size: var(--primary-font-size);
      color: var(--drak-light-grey-color);
      width: 100%;
    }

    &.checkbox-txt {
      align-items: self-start;
    }

    &.checkbox-txt span {
      padding: 0 10px 0 0;
    margin-top: -2px;
&:hover{background: none;}
      svg {
        font-size: 25px;
        fill: var(--primary-color);
      }
    }

    .Mui-checked {
      color: var(--primary-color);
    }
  }

  .form-control {
    .MuiOutlinedInput-input {
      color: var(--drak-light-grey-color);
      font-size: var(--font-normal);
      padding: 0 15px;
      height:46px;
      line-height:46px;
    }

    textarea.MuiOutlinedInput-input{padding: 0; line-height:24px;}

    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--border-color) !important;
      outline: none;
    }

    .MuiSwitch-switchBase {
      color: var(--drak-grey-color);
    }

    .MuiSwitch-track {
      background: var(--light-grey-color);
    }

    .Mui-checked span.MuiSwitch-track {
      background: var(--primary-color);
    }

    .MuiSwitch-switchBase.Mui-checked {
      color: var(--primary-color);
    }
  }

  .css-umzx0k-MuiClock-pin {
    background: var(--primary-color);
  }

  .css-d0vs79-MuiClockPointer-root {
    background: var(--primary-color);
  }

  .css-eg3pzz-MuiClockPointer-thumb {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }

  .css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
    background: var(--primary-color);
  }

  .css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
    background: var(--primary-color);
  }

  .text-line {
    position: relative;
  }

  .text-line:before { content: "";
    display: block;
    background: var(--primary-bg-color);
    width: 100%;
    height: 1px;
    position: relative;
    top: 12px;
  }

  .text-line span {
    color: var(--black-color);
    font-size: var(--primary-font-size);
    background: var(--primary-bg-color);
    position: relative;
    background: var(--primary-bg-color);
    padding: 0 10px;
  }

  .blue-grey-bg {
    background: var(--primary-bg-color);
  }

  .modalPopupTxt {
    .modalPopupOverflow {
      height: 100%;
      overflow-y: auto;
      display: flex;
      padding: 50px 20px;
    }

    .modalPopupTxt-inner {
      position: relative;
      padding: 30px;
      @media screen and (max-width:767px) {
        padding:20px;
    }
      width: 100%;
      max-width: 620px;
      border-radius: 8px;
      background: var(--white-color);
      border: none;
      outline: none;
      margin: auto;

      .closepopup {
        position: absolute;
        top:0px;
    right:0px;
        margin: 20px;
        padding: 0;
        width: 24px;
        min-width: auto;

        svg {
          font-size: 30px;
          fill: var(--black-color);
        }

        span {
          color: var(--white-color);
        }

        &:hover {
          background: none;
        }
      }
    }
  }
}

@media screen and (max-width:767px) {
  body {
    .w-100-xs {
      width: 100%;
    }

    .hiddenXS {
      display: none;
    }

    .ml-xs-0 {
      margin-left: 0;
    }

    .pt-xs-10 {
      padding-top: 10px;
    }

    .pt-xs-20 {
      padding-top: 20px;
    }
  }

}

@media screen and (min-width:768px) {
  .hiddenSM {
    display: none;
  }
}