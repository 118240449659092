body {
   .profleInfo {
      background: var(--white-color);
      padding-top: 40px;

      .profilelightBG {
         background: var(--light-blue-bg);
         border-radius: var(--border-r-8);
         padding: 35px;
         @media screen and (max-width:767px) {
            padding:20px;
             
          }
          @media screen and (min-width:821px) and (max-width:1024px) {
            padding: 25px; 
          }
      }

      .profilelayout {
         max-width: 1130px;
         margin: auto;
         border-radius: var(--border-r-8);
         background: var(--white-color);
         padding: 45px;
         @media screen and (max-width:820px) {
            padding: 20px;
          }
          @media screen and (min-width:821px) and (max-width:1024px) {
            padding: 25px; 
          }

         .shopBannerImg {
            border-radius: var(--border-r-8);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 0 35px;
            position: relative;

            .shopImg {
               border-radius: var(--border-r-8);
               max-height: 150px;
               object-fit: cover;
            }

            a.editshopBanner {
               border: 1px solid;
               width: 40px;
               height: 40px;
               position: absolute;
               bottom: 0;
               right: 0;
               background: #FFF;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 50px;
               margin: -8px;
            }

            .browseBtnOuter {
               border: 1px dotted #C4CDD5;
               min-height: 150px;
               width: 100%;
               flex-wrap: wrap;
               font-weight: 400;
               text-transform: none;
               align-content: center;
               padding: 0;

               .browseTxt {
                  color: var(--black-color);
                  font-size: 18px;

                  b {
                     font-size: 18px;
                     font-weight: 700;
                     color: var(--primary-color);
                  }
               }

               small.photoSizetxt {
                  font-size: 14px;
                  width: 100%;
                  color: var(--black-color);
               }

               &:hover {
                  background: none;
               }

            }

         }

         .shopLogoImg {
            min-height: 150px;
            border: 1px solid #C4CDD5;
            border-radius: var(--border-r-8);
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 50px;
            @media screen and (max-width:820px) {
               padding: 20px;
               justify-content: center;
             }
             @media screen and (min-width:821px) and (max-width:1024px) {
               padding: 20px; 
             }


            .shopLogo {
               width: 90px;
               height: 90px;
               display: flex;
               align-items: center;
               justify-content: center;
               border: 1px solid #C4CDD5;
               border-radius: 100px;
               margin-right: 25px;
               @media screen and (max-width:820px) {
                  margin-right:0px; margin-bottom: 15px;
                }
            }

            .btn {
               height: 40px;
               line-height: 40px;
               min-width: 150px;
               font-size: var(--font-small);
               font-weight: 500;
               text-transform: none;
            }

            .shopUserImg {
               width: 90px;
               height: 90px;
               display: block;
               border-radius: 100px;
               margin-right: 25px;
               @media screen and (max-width:820px) {
                  margin-right:0px; margin-bottom: 15px;
                }

               img {
                  width: 90px;
                  height: 90px;
                  border-radius: 100px;
                  border: 1px solid #C4CDD5;
                  display: block;
               }

            }
         }

         .editProfile {
            margin-right: 0px;
            margin-bottom: 0px;
            font-size: var(--font-small);

            svg {
               font-size: 20px;
               margin-right: 0px;
            }
         }

         .profileInfoOuter {
            padding: 40px 15px 0;
            @media screen and (max-width:820px) {
               padding: 20px 0 0;
                
             }
            .MuiGrid-item {
               padding: 0 15px 45px;
               color: var(--black-color);
               @media screen and (max-width:820px) {
                  padding: 0 0 25px;
                   
                }
               label {
                  display: block;
                  font-size: var(--font-medium);
                  font-weight: 700;
                  padding-bottom: 5px;
                  @media screen and (max-width:820px) {
                     font-size: var(--font-normal);
                      
                   }
               }

               span {
                  font-size: var(--font-small);
               }
            }
         }

         .editProfile {

            .MuiGrid-item {
               padding: 0px 15px 25px;

               label {
                  display: block;
                  font-size: var(--font-medium);
                  font-weight: 700;
                  padding-bottom: 5px;

                  .closeTimingtxt {
                     font-size: var(--font-medium);
                     font-weight: 700;
                     padding-left: 75px;
                  }
               }

               .timefeild>.MuiOutlinedInput-root {
                  padding-right: 0;

                  button {
                     margin-right: 0;
                  }
               }

               .sundayClose {
                  padding-top: 30px;

                  label {
                     font-weight: 400;
                     font-size: var(--font-small);
                  }
               }
            }

         }
      }
   }

}