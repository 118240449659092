body {
  header.siderbar-header {
    background: var(--white-color);
    box-shadow: none;
    padding: 8px 0;
    border-bottom: 1px solid var(--border-color);

    @media screen and (max-width:767px) {

    margin-left: 0px;
    width: 100%;

    }

    .mainHeading {
      font-size: 24px;
      color: var(--drak-light-grey-color);
    }

    .profile-menuicon {
      display: flex;
      align-items: center;
      cursor: pointer;

      .userName {
        width: 40px;
        height: 40px;
        background: var(--primary-color);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font-small);
        cursor: pointer;

        @media screen and (max-width:767px) {
          width: 36px;
          height: 36px;
        }
      }

      .userNamimage {
        display: flex;
        align-items: center;
        color: #818997;
        margin: 0 5px 0 0;
        font-size: 15px;
        font-weight: 500;
      }

      .userNamimage img {
        width: 50px;
        height: 50px;
        display: block;
        object-fit: contain;
        border: 1px solid #2A236C;
        padding: 4px;
        border-radius: 55px;
        margin: 0 15px 0 0;
      }


      svg {
        fill: #818997;
      }

      .arrowdown {
        color: #818997;
        font-size: 30px;
      }
    }

    .mui-icon {
      width: 36px;
      height: 40px;
      margin-right: 20px;
      background: var(--white-color);
      margin-top: 7px;

      @media screen and (max-width:767px) {
        width: 36px;
        height: 36px;
      }

      svg {
        color: #818997;
        font-size: 36px;
      }

      .MuiBadge-badge {
        background: var(--primary-color);
        width: 22px;
        height: 22px;
        border-radius: 100%;
        line-height: 20px;
        margin: 0;
        font-size: 12px;
        position: absolute;
        left: -4px;
        top: 0;
      }
    }
  }

  .profileSubmenu ul {
    min-width: 150px;

    li {
      color: var(--primary-color);
      line-height: normal;
      outline: none;

      a {
        display: block;
      }
    }
  }

  .logo-menuicon {
    padding: 0;
    padding-left: 14px;
    min-height: 80px;

    @media screen and (max-width:767px) {
      padding-left: 6px;
      min-height: 73px;
    }
  }

  .menuicon {
    color: var(--primary-color);
    background: none;
    margin-right: 30px;

    @media screen and (max-width:767px) {
      margin-right:10px;
      display: block;
    }

    padding: 0 10px;

    &:hover {
      background: none;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .menuicon.closemenu-icon {
    margin-right: auto;

  }

  .closemenu-icon .RightIcon svg {
    font-size: 20px;
    margin-right: -4px;
    margin-top: 6px;
  }

  .sidebar {
    .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
      box-shadow: 0px 4px 12px rgb(0 0 0 / 20%);
      background-image: linear-gradient(#0070BC, #0096FC);
      padding: 0;
    }

    .sidebarInnerArea {
      padding: 0 16px;
    }

    .usershortName {
      display: flex;
      align-items: center;
      padding: 20px 0 10px;

      .shortletter {
        width: 54px;
        min-width: 54px;
        height: 54px;
        border: 1px solid var(--drak-light-grey-color);
        border-radius: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFF;
        font-size: 18px;
        margin: 0 15px 0 0;
      }

      .userwelcome {
        border-left: 1px solid #C3E7FF;
        min-height: 55px;
        display: flex;
        align-items: center;
        padding: 0 0 0 15px;
        color: #FFF;
        font-size: 17px;
        line-height: 22px;
        font-weight: 400;
      }
    }

    .sidebarlinks {
      margin: 8px 0 0 0;
      overflow: hidden;

      li {
        background: transparent;
        padding: 5px 0 0 0;
        flex-wrap: wrap;

        a {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0;
          font-weight: 400;
          padding: 0;
          border-radius: 10px;
          color: var(--white-color);

          span.menuItem {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 2;
            padding: 12px 15px;
            font-size: 17px;
            font-weight: 400;
          }
        }

        svg.icon {
          margin-right: 16px;
        }

        span.subMenuArrow {
          position: absolute;
          right: 0;
          top: 6px;
          z-index: 1;
          background: #f000;
          width: 50px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg.arrow {
            margin-left: 0;
            font-size: 18px;
            fill: var(--white-color);
          }
        }
      }

      li:hover>a,
      li a.active {
        background: #C3E7FF;
        color: #0070BC;
      }

      li:hover>.subMenuArrow svg.arrow,
      li a.active+.subMenuArrow svg.arrow {
        fill: #0070BC;
      }

      .submenu {
        padding: 0 0 0 30px;
        width: 100%;

        li:first-child {
          border-top: none;
        }

        li a {
          padding: 10px 16px 10px 14px;
        }

        span.badge {
          background: #DDE9FF;
          width: 45px;
          height: 25px;
          border-radius: 40px;
          line-height: 25px;
          color: #2A236C;
          text-align: center;
          margin-left: auto;
          font-weight: 600;
        }
      }
    }
  }

  @media screen and (max-width:767px) {

  .sidebar{position: absolute; left: 0; top: 0;  
    z-index: 1201; 
    } 
  }

  @media screen and (min-width:767px) and (max-width:1024px) {
    .sidebar{position: absolute; left: 0; top: 0;  
      
      } 
  }

  .dashboardRightcontent {
    padding: 0 20px 0px;
    @media screen and (min-width:767px) and (max-width:1024px) {
      padding-left: 100px;
    }
    width: 100%;

    .filter-bg {
      background: var(--white-color);
      padding: 15px 20px;
      margin-left: -20px;
      margin-right: -20px;

      .search-bg {
        background: var(--white-color);
        border-radius: var(--border-r-4);
        display: flex;
        padding: 5px 15px 5px 0;

        button {
          background: none;
          color: var(--black-color);

          :hover {
            background: none;
          }
        }

        input {
          color: var(--drak-grey-color);
        }
      }

      button.btn {
        height: 40px;
        line-height: 30px;
      }
    }

    .customselect {
      .MuiSelect-select {
        background: var(--white-color);
        border: 1px solid var(--border-color);
        line-height: 22px;
        height: auto;
        padding: 10px 10px 9px 10px;
      }

      svg {
        fill: var(--primary-color);
      }

      fieldset {
        border: none !important;
      }

      &.custom-filter {
        max-width: 150px;
      }
    }

    footer.footer-bg {
      margin: 60px -20px 0 -20px;

      .container {
        max-width: 100%;
        padding: 0 5px;
      }
    }
  }
}