.dashboardRightcontent {

  .custom-card-layout {
    .card-body {
      background: var(--white-color);
      padding: 20px;
      border-radius: var(--border-r-8);
      box-shadow: 0 0 5px 0px rgb(0 0 0 / 25%);

      h2 {
        font-size: 17px;
        color: var(--primary-color);
        padding-bottom: 15px;
        font-weight: 500;
      }

      h3 {
        font-size: 18px;
        color: var(--primary-color);
      }

      >div {
        align-items: center;
      }

      .mui-icon {
        background: var(--white-color);
        width: 82px;
        height: 82px;
        padding: 0;
        margin-left: auto;

        svg {
          color: var(--primary-color);
          font-size: 34px;
        }

        &.ShoppingBag {
          background-color: #D6EEFF;
        }

        &.AttachMoney {
          background-color: #F2ECFB;

          svg {
            color: #703BDB;
          }
        }

        &.Diversity2O {
          background-color: #FEF4EF;

          svg {
            color: #FF8751;
          }
        }

        &.Article {
          background-color: #FEEEF1;

          svg {
            color: #FB4A7B;
          }
        }
      }
    }
  }

  .shopBGbanner {
    background: url(../../images/MaskgroupBG.png) no-repeat center;
    background-size: cover;


    .breadcrumbLinks {
      display: flex;

      @media screen and (min-width:767px) {
        padding-bottom: 15px;

      }
    }


    @media screen and (min-width:768px) {
      .shopImgOuer {
        display: block;
        flex-basis: auto;
        min-width: 110px;
        max-width: 110px;
      }
    }

    .shopImgInner {
      display: block;

      img {
        display: block;
        border-radius: 8px;
      }
    }

    @media screen and (max-width:767px) {
      .shopImgInner {
        display: block;
        min-width: 100px;
        max-width: 100px;
      }

      .shopImgOuer {
        display: flex;
        align-items: center;
      }
    }


    .addressInfo .SvgIcon svg {
      fill: var(--primary-color);
      margin-right: 6px;
      font-size: 25px;
    }

  }

  .xm-banner {
    align-items: center;

    .xm_shoplogo {
      max-width: 140px;
      padding: 10px;
      background: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-r-4);

      img {
        max-width: 140px;
        max-height: 140px;
      }
    }
  }

}


.productshowList {
  .productshowList-left {
    .subMenuArrow {
      font-size: var(--font-normal);
      font-weight: 500;
      color: var(--white-color);
    }

    .submenuContent {
      border: 1px solid var(--primary-bg-color);
      margin-top: -2px;
      background-color: var(--white-color);
      border-radius: 0 0 4px 4px;

      ul {
        padding-top: 2px;
      }
    }

    .DashboardLinkBG {
      background: #F5F7FB;
      border-radius: 8px;
      padding: 10px;
    }

    ul li {
      padding: 0;
      margin-bottom: 2px;
      border-radius: 8px;

      .list-btn {
        border: none;
        background: none;
        color: var(--black-color);
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        width: 100%;
        text-align: left;
        cursor: pointer;
        border-radius: 8px;
        padding: 15px;

        @media screen and (max-width:767px) {
          padding: 12px 15px;

        }
      }

      &:hover,
      &.active {
        background-color: var(--white-color);


      }

      &:hover,
      &.active {
        .list-btn {
          color: var(--primary-color);
        }
      }
    }
  }

  .productshowList-right {
    h3 {
      background: var(--white-color);
      padding: 10px;

      @media screen and (max-width:767px) {
        padding: 0 0 15px;
      }

      display: block;
    }

    .productRow {
      padding: 10px;

      @media screen and (max-width:767px) {
        padding: 0px;
      }


      .productCol {
        padding: 15px;
        border-radius: 4px;

        @media screen and (max-width:767px) {
          padding: 15px 0;

        }

        &:hover {
          box-shadow: 0 0 4px rgb(0 0 0 / 35%);
        }

        .prodctImg {
          width: 100%;
          min-height: 180px;
          background: var(--white-color);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h4 {
          font-size: var(--font-normal);
          font-weight: 500;
          color: var(--drak-light-grey-color);
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
          padding: 10px 0 0 0;
        }

        p {
          font-size: var(--font-small);
          color: var(--black-color);
          line-height: 24px;
          padding: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 62px;
          min-height: 62px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .count-txt {
          padding: 10px 0 25px 0;

          span {
            font-size: var(--font-small);
            font-weight: 500;
            color: var(--primary-color);
          }

          span:last-child {
            margin-left: auto;
          }
        }

        button.addTOcartBtn {
          width: 100%;
          background: #F6F6F6;
          border: 1px solid #D9D9D9;
          box-shadow: none;
          border-radius: 80px;
          color: #000;
        }
      }
    }
  }
}