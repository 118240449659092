body {


  .newOrderlistlayout {

    .newOrderlist-bg {
      padding: 15px;
      background: var(--light-blue-bg);
      border-radius: var(--border-r-4);
      position: relative;
      overflow: hidden;

      &.newOrderlist {
        h2 {
          padding: 25px 20px;
          border-bottom: 1px solid var(--border-color);
        }

        li {
          display: block;
          padding: 0;
          margin-bottom: 1px;

          a {
            padding: 15px;
            font-size: var(--font-small);
            color: var(--black-color);
            font-weight: 400;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0;

            svg {
              margin-left: auto;
              font-size: 22px;
              fill: var(--drak-light-grey-color);
            }

            dd {
              width: 100%;
            }

            span {
              margin-top: 15px;
              position: relative;
              padding-left: 32px;
              display: block;
              color: var(--drak-light-grey-color);

              svg {
                margin-left: 0;
                position: absolute;
                left: 0;
                top: -4px;
              }

            }
          }

          &:hover,
          &.active {
            background: var(--primary-color);

            a {
              color: var(--white-color);

              span {
                color: var(--white-color);
              }
            }

            svg {
              fill: var(--white-color);
            }
          }

          &:last-child {
            border-bottom: none;
          }

          &.listHeading {
            background: var(--gradients-color);
            border-radius: var(--border-r-4);

            a {
              color: var(--white-color);

              svg {
                font-size: 30px;
                fill: var(--white-color);
              }
            }
          }
        }

        li:last-child {
          margin-bottom: 0;
        }

      }
    }


    .newOrderInfo {
      @media screen and (max-width:1199px) {
        margin-top: 30px;
      }

      @media screen and (min-width:1200px) {
        margin-left: 30px;
      }

      .newOrderHeader {
        background: var(--tablethcolor);
        padding: 25px 15px;
        font-family: var(--primary-font);
        font-weight: 700;
        color: var(--primary-color);
        margin-top: 25px;
        margin-bottom: -6px;
        border-radius: 6px 6px 0 0;
      }
    }


    .newOrderRow {
      padding: 10px 15px;

      align-items: center;
      background: var(--white-color);
      border-radius: 6px;
      box-shadow: 0px 4px 4px 0px #92929226;
      margin-bottom: 15px;

      @media screen and (max-width:767px) {
        margin-bottom: 0px;
        margin-top: 15px;


      }

      .newOrderCol {


        .productimgTxt {
          display: flex;
          align-items: center;

          .productimg {
            width: 100%;
            max-width: 72px;
            margin-right: 15px;

            img {
              width: 72px;
              height: 72px;
              display: block;
            }
          }

          .productinfo {
            h3 {
              font-size: var(--font-normal);
              font-weight: 700;
              color: var(--drak-light-grey-color);
              padding-bottom: 5px;
            }
          }
        }

        span.popupTxt {
          font-size: var(--font-small);
          font-weight: 400;
          color: var(--drak-light-grey-color);
          padding: 0;
          background: none;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .priceBtn {
          border: 1px solid #D9D9D9;
          display: block;
          padding: 0;
          border-radius: 4px;
          height: 32px;
          line-height: 32px;
          text-align: center;
        }

        .addPricetxt {
          justify-content: center;
          font-weight: 500;
          color: var(--drak-light-grey-color);
        }

        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
          color: var(--drak-grey-color);
        }

        .css-1yjjitx-MuiSwitch-track {
          background: #D9D9D9;
        }

        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
          color: var(--primary-color);
        }

        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
          background: #B3D9F3;
        }

        @media screen and (max-width:767px) {
          span.iPhoneTxt {
            font-weight: 500;
            color: var(--primary-color);
            padding: 15px 0 5px 0;
            display: block;
          }

          .checkBtn {
            margin-bottom: -12px;
            display: block;
            margin-top: -8px;
          }
        }
      }
    }


  }


  .orderStepBar {
    .stepBar {
      .Mui-completed {
        .MuiStepLabel-label {
          opacity: 1;
          color: var(--primary-color);
        }

        svg {
          fill: var(--primary-color);
          border: none;
        }

        .css-z7uhs0-MuiStepConnector-line {
          border-color: var(--primary-color);
        }
      }

      .MuiStepLabel-label {
        color: var(--black-color);
        font-weight: 500;

      }

      svg {
        position: relative;
        z-index: 2;
        fill: #D9D9D9;
        border: 2px solid var(--primary-color);
        border-radius: 40px;

        text.MuiStepIcon-text {
          opacity: 0;
          font-size: 0;
        }
      }

      .css-zpcwqm-MuiStepConnector-root {
        left: calc(-45% + 0px);
        right: calc(45% + 19px);
      }

      .css-z7uhs0-MuiStepConnector-line {
        border: 1px solid var(--primary-color);
        margin-top: -2px;
      }

    }

    button.btn {
      border-radius: 50px;
      padding: 0 30px;
    }
  }

  .invoice-txt-area {
    background: var(--primary-color);
    padding: 20px;
    margin-top: 25px;
    border-radius: 4px;
    width: 100%;
    max-width: 350px;

    h2 {
      font-size: var(--font-subheading);
      color: var(--white-color);
      border-bottom: 1px solid var(--white-color);
      margin:-5px -20px 10px;
      padding: 0 20px 15px;
    }

    >div {
      padding: 10px 0;

      color: var(--white-color);
      font-weight: 400;
      font-size: var(--font-small);
    }

    div:last-child {
      border-bottom: none;
      padding-bottom: 5px;
    }

    .amount {
      font-weight: 500;
    }
  }
}