:root {
    --basic: #F8A233;
    --standard: #0CADFE;
    --premium: #7955FD;
}


.subscriptionOuter {
    background: var(--light-blue-bg);
}

/* SWITCH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.switch-wrapper {
    position: relative;
    display: inline-flex;
    padding: 4px;
    border: 1px solid lightgrey;
    border-radius: 30px;
    background: var(--white-color);
}

.switch-wrapper [type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
    z-index:2; 
} 

.switch-wrapper [type="radio"]:checked#monthly~label[for="monthly"],
.switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"] {
    color: var(--white-color);
}

.switch-wrapper [type="radio"]:checked#monthly~label[for="monthly"]:hover,
.switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"]:hover {
    background: transparent;
}

.switch-wrapper [type="radio"]:checked#monthly+label[for="yearly"]~.highlighter {
    transform: none;
}

.switch-wrapper [type="radio"]:checked#yearly+label[for="monthly"]~.highlighter {
    transform: translateX(100%);
}

.switch-wrapper label {
    font-size: 16px;
    z-index: 1;
    min-width: 100px;
    line-height: 32px;
    cursor: pointer;
    border-radius: 30px;
    pointer-events: auto;
    transition: color 0.25s ease-in-out;
}

.switch-wrapper label:hover {
    background: var(--light-grey-color);
}

.switch-wrapper .highlighter {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 30px;
    background: var(--primary-color);
    transition: transform 0.25s ease-in-out;
}

/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.table-wrapper {
    overflow-x: auto;


    table {
        width: 100%;
        border-spacing: 30px;
    }

    table tr {
        display: flex;
    }

    table thead th,
    table tbody td {
        width: 25%;
        min-width: 150px;
        padding: 0;
        text-align: center;
        border-bottom: none;
        font-size: var(--font-small);
    }

    table thead th {
        padding: 0px;
    }

    table thead th .heading {
        padding: 25px 15px;
        border-radius: 6px 6px 0 0;
        font-family: var(--primary-font);
        font-size: 28px;
        color: var(--white-color);
    }

    table th:nth-child(2) .heading {
        background: var(--basic);
    }

    table th:nth-child(3) .heading {
        background: var(--standard);
    }

    table th:nth-child(4) .heading {
        background: var(--premium);
    }

    table thead th {
        margin-left: 20px;

        .info {
            position: relative;
            padding: 25px 15px;
            background: #FAFAFC;
        }

        &:nth-child(2) .info {
            background: var(--white-color);
        }

    }

    table thead tr:nth-child(2) {
        background: var(--white-color);
        border-radius: 10px 10px 0 0;

        th:first-child .info{background:var(--white-color);}
    }

    table thead th .amount {
        font-size: 28px;
        font-weight: 700;
    }

    table thead th .amount span {
        display: block;
        padding-top: 5px;
    }

    table thead th:nth-child(2) .amount {
        color: var(--basic);
    }

    table thead th:nth-child(3) .amount {
        color: var(--standard);
    }


    table thead th:nth-child(4) {
        margin-right: 20px;
    }

    table thead th:nth-child(4) .amount {
        color: var(--premium);
    }

    table thead th .billing-msg,
    table thead th .amount span {
        font-weight: normal;
        font-size: 0.8rem;
    }

    table tbody tr {
        background: #FFF;

        &:nth-child(even) td {
            background-color: #FAFAFC;

            &:nth-child(2) {
                background: #FFF;
            }
        }

        &:nth-child(odd) td {
            background-color: #F3F3F5;
        }



        &:last-child {
            border-bottom: 50px solid #FFF;
            border-radius: 0 0 10px 10px;

            td {
                border-radius: 0 0 10px 10px;
                background: #fafafc;

                &:nth-child(2) {
                    background: var(--white-color);
                }

            }
        }
    }



    table tbody tr td button {
        border-radius: 8px;
        padding: 10px 25px;
        font-size: var(--font-normal);
        margin: 10px 0;
        transition: all 0.2s;
        cursor: pointer;
        white-space: nowrap;
    }

    table tbody tr td:nth-child(2) button {
        color: var(--white-color);
        border: 1px solid var(--basic);
        background: var(--basic);
    }


    table tbody tr td:nth-child(3) button {
        color: var(--white-color);
        border: 1px solid var(--standard);
        background: var(--standard);
    }


    table tbody tr td:nth-child(4) button {
        color: var(--white-color);
        border: 1px solid var(--premium);
        background: var(--premium);
    }

    table tbody td {
        padding: 15px;
        margin-left: 20px;
    }

    table tbody td:first-child {
        text-align: left;
    }

    table tbody td:last-child {
        margin-right: 20px;
    }

    table .hide {
        display: none;
    }

    /* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */


    .checkIcongreen {
        color: #3BDB7B;
        display: block;
        margin: auto;
    }

    .closeIconred {
        color: #F33D3C;
    }

}