body{
.InnerPageHeader { background: url(../../../images/header-blue-bg.png) no-repeat center; background-size: cover;
  .shopImg {
    background: var(--light-grey-color);
    padding: 20px 15px;
    border-radius: var(--border-r-8); width: 150px; min-height: 150px; display: flex; align-items: center;
    img{width: 100%; height: auto;} 
  } 
    li{color: var(--white-color);
      a{color: var(--white-color);}
    } 
    .font-subheading{color: var(--white-color);}
    p{color: var(--white-color);}
 
}

.shopListInfo { 
  .storeImg {
    background: var(--light-grey-color); 
    border-radius: var(--border-r-8); width:85px; height:85px; object-fit: cover; display: block;
  }
  .lightGreyBG{-webkit-transition:all .4s ease-in-out; -moz-transition:all .4s ease-in-out; -o-transition:all .4s ease-in-out; -ms-transition:all .4s ease-in-out;}
  a:hover .lightGreyBG{box-shadow: 0 0 12px 0px rgb(0 0 0 / 28%); background: var(--white-color);}
  
  span.SvgIcon{
font-size: var( --font-xxs); color: var(--drak-light-grey-color);
  
     svg {
      color: var(--primary-color);
    font-size: 20px;
    margin:0px 3px 0 0;
  }}
}
}

.customPagination ul.MuiPagination-ul {
  padding-top: 25px;
  justify-content: flex-end;

  li {


  button.MuiButtonBase-root{border-color:#DFE3E8; color:#666; font-weight:600; font-size: var(--font-small);}

  button.MuiButtonBase-root:hover,
  button.Mui-selected,
  button.Mui-selected:hover{
    background: #FFF;
    border-color: #4B8BAE;
    color: #4B8BAE;
  }

    
  button.MuiButtonBase-root.Mui-disabled{
    background: #919EAB;
    opacity: 1;
    border-color: #919EAB;
    color: #C4CDD5;

  }


  div.MuiPaginationItem-root {
    border: #DFE3E8 1px solid;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    line-height: 23px;
    color: #666;
    font-weight: 600;
    font-size: var(--font-small);
}

  }

}

 