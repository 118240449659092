.checkOut-left {
  .checkOutBlockouter {
    padding-left: 50px;
    position: relative;

    .btn.button-radius{
padding: 0 30px;
    }

    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0%;
      border-left: 1px dashed;
    }

    &:first-child::before {
      top: 50%;
      height: 50%;
    }

    &:last-child::before {
      top: -50%;
    }

    span.roundeBox {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 2px solid var(--primary-color);
      display: block;
      position: absolute;
      left: -19px;
      top: 50%;
      background: var(--light-grey-color);
      margin-top: -20px;

      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50px;
        position: absolute;
        top: 0;
        left: 0;
        margin: 8px;
        background: none;
      }
    }

    &.active span.roundeBox {
      background: var(--white-color);
    &::before {
      background: var(--primary-color);
    }
  }
    &.active.checked span.roundeBox{
      background: var(--primary-color);

      &::before{left: 6px;
        top: -1px;
        width: 7px;
        height: 16px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 0;}
    }
  }

  span.creditDebitTxt {
    background: #DFE3E8;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px; font-size: var(--font-small); color: var(--black-color);

    svg{margin-right:10px;}
}

.ccpayInfo{
position: relative;
  &::before{
    content: "";
    display: block;
    position: absolute;
    left:-34px;
    top: 0;
    height: 100%; width: 2px; background: var(--drak-light-grey-color);}


    .ElementsApp input{height: 40px; border: 1px solid #000;}
}

  .storeTime {
    .timeIcon {
      margin: -2px 5px 0 0;

      @media screen and (max-width:767px) {
        margin: -1px 5px 0 0;
      }
    }

    b {
      padding: 0px 2px 0 5px;
    }

    @media screen and (max-width:767px) {
      .mobileTime {
        align-items: flex-start;
      }
    }
  }

  .dateTimeerShow {
    border: 1px solid var(--drak-light-grey-color);
    border-radius: var(--border-r-4);
    padding: 20px 15px;

    svg {
      fill: var(--drak-light-grey-color);
    }

    .changedateTime {
      height: auto;
      line-height: normal;
      padding: 0;

      &:hover {
        background: none;
        box-shadow: none;
      }
    }
  }

}

.checkOut-right {
  .addTocartBar {
    .CartHeader {
      background: none;

      .cartItems {
        border-right: none;
        padding-right: 0;
        margin-right: 0; color: var(--black-color);
      }
    }

    .addCartProductList {
      height: auto;
      overflow-y: hidden;
      padding: 0 0 10px 0;

      .checkOutPriceInfo {
        padding-left: 0;

        span {
          justify-content: space-between;
        }
      }
    }
  }

  .invoiceInfo {
    h2 {
      padding: 15px;
    }

    ul {
      padding: 0px 0 15px;
    }

    li {
      padding: 12px 20px;
      border-bottom: 1px solid var(--light-grey-color);
      color: var(--drak-light-grey-color);
      font-weight:400;
      font-size: var(--font-small);
    }

    li:last-child {
      border-bottom: none;
      padding-bottom: 5px;
    }

    .amount {
      font-weight: 500;
    }
  }



}

.checkoutSingup.login-wrapper {
  padding: 0;

  .login-bg {
    background: none;
    padding: 30px;

    .text-line span {
      background: var(--white-color);
    }
  }
  .btn.button-radius{height: 48px;
    line-height: 48px;}
}