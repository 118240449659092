body {

    @media screen and (max-width:767px) {
        .addProductBar {
            width: 300px;
        }
    }


    .border-bottom {
        border-bottom: 1px solid var(--border-color);
    }

    .addProductDrawer {
        z-index: 1201;


        .AddProductHeader {
            background: #F3F6F9;
            padding: 15px 25px;

            button {
                min-width: auto;

                & {
                    background: none;
                }
            }
        }

        .productField label {
            font-size: var(--font-normal);
            font-family: var(--primary-font);
            font-weight: 500;
            color: var(--drak-light-grey-color);
            padding-bottom: 9px;
        }

        .addProductImg label {
            flex-wrap: wrap;
            justify-content: center;
            display: flex;
            text-align: center;
            text-transform: none;
            background: #DFE3E8;
            padding: 30px;

            img {
                max-width: 50px;
            }
        }

    }


    .addProductlayout {


        .productTabmenu {
            position: relative;
            @media screen and (max-width:767px) {
            > .MuiTabs-scroller{
                overflow-x: scroll!important;
            }
        }

            &::after {
                content: "";
                display: block;
                height: 1px;
                width: 100%;
                background: #94A3B0;
                position: absolute;
                left: 0;
                top: 100%;
                z-index: -1;
                margin-top: -2px;
            }

            button.Mui-selected {
                color: var(--primary-color);
            }

            span.css-1aquho2-MuiTabs-indicator {
                height: 3px;
                background: var(--primary-color);
            }
        }




        .addProductlistHeader {
            background: var(--tablethcolor);
            padding: 25px 15px;
            font-family: var(--primary-font);
            font-weight: 700;
            color: var(--primary-color);
            margin-top: 0px;
            margin-bottom: -6px;
            border-radius: 6px 6px 0 0;
        }


        .addProductRow {
            padding: 10px 15px;
            align-items: center;
            background: var(--white-color);
            border-radius: 6px;

            .addProductCol {


                .productimgTxt {
                    display: flex;
                    align-items: center;

                    .productimg {
                        width: 100%;
                        max-width: 72px;
                        margin-right: 15px;

                        img {
                            width: 72px;
                            height: 72px;
                            display: block;
                        }
                    }

                    .productinfo {
                        h3 {
                            font-size: var(--font-normal);
                            font-weight: 700;
                            color: var(--drak-light-grey-color);
                            padding-bottom: 5px;
                        }
                    }
                }



                span.popupTxt {
                    font-size: var(--font-small);
                    font-weight: 400;
                    color: var(--drak-light-grey-color);
                    padding: 0;
                    background: none;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                .priceBtn {
                    border: 1px solid #D9D9D9;
                    display: block;
                    padding: 0;
                    border-radius: 4px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    background: var(--white-color);
                    width: fit-content;
                    margin: auto;
                    min-width: 110px;
                }

                .addPricetxt {
                    justify-content: center;
                    font-weight: 500;
                    color: var(--drak-light-grey-color);
                    line-height: 30px;
                }

                span.editBtn {
                    font-size: var(--font-small);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--primary-color);
                    cursor: pointer;

                    svg {
                        margin-right: 5px;
                    }
                }

                .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
                    color: var(--drak-grey-color);
                }

                .css-1yjjitx-MuiSwitch-track {
                    background: #D9D9D9;
                }

                .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
                    color: var(--primary-color);
                }

                .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
                    background: #B3D9F3;
                }

                @media screen and (max-width:767px) {
                    span.iPhoneTxt {
                        font-weight: 500;
                        color: var(--primary-color);
                        padding: 15px 0 5px 0;
                        display: block;
                    }

                    .checkBtn {
                        margin-bottom: -12px;
                        display: block;
                        margin-top: -8px;
                    }
                }
            }
        }

        .addProductOuter .addProductRow:nth-child(odd) {
            background: var(--light-blue-bg);
        }

        .addProductOuter .addProductRow:nth-child(even) {
            background: var(--white-color);
        }

    }
}